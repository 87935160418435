import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { SearchOptionsContext, ISearchContext } from './useSearch';

enum SortDirection {
    Asc,
    Desc,
}

interface ISort {
    field: string;
    direction: SortDirection;
}

export default function Sort({ children, field, sort }: PropsWithChildren<{ field: string, sort: any }>) {
    const { sort: { toggleSort } } = useContext<{ sort: { toggleSort: any } }>(SearchOptionsContext);

    return (
        <span className="cursor-pointer select-none" onClick={() => toggleSort(field)}>
            {children}
            {sort?.field === field &&
                <FontAwesomeIcon
                    className="text-blue-700"
                    icon={sort?.direction === SortDirection.Asc ? faLongArrowAltDown : faLongArrowAltUp}
                    fixedWidth />
            }
        </span>
    );
}

export function useSort(context: any) {
    const searchContext = useContext<ISearchContext>(context);
    const initialState: ISort | undefined = loadSortFromSearch(searchContext.search);
    const [state, setState] = useState(initialState);

    function reducer(state: ISort | undefined, field: string) {
        if (field !== state?.field) {
            return { field, direction: SortDirection.Asc };
        } else if (state?.direction === SortDirection.Asc) {
            return { field, direction: SortDirection.Desc };
        }

        return undefined;
    }

    function loadSortFromSearch(search: URLSearchParams) {
        const sort = search.get('sort');

        if (!sort) {
            return undefined;
        } else if (sort.charAt(0) === '-') {
            return { field: sort.substr(1), direction: SortDirection.Desc }
        } else {
            return { field: sort, direction: SortDirection.Asc }
        }
    }

    function toString(state: ISort | undefined) {
        return state ? `${state.direction === SortDirection.Desc ? '-' : ''}${state.field}` : '';
    }

    function toggleSort(field: string) {
        searchContext.dispatch({ type: 'set', param: 'sort', value: toString(reducer(state, field)) });
    }

    useEffect(() => {
        setState(loadSortFromSearch(searchContext.search));
    }, [searchContext.search]);

    return [state, toggleSort];
}