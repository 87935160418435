export let _;

export const translate = load => {
    _ = I18n.use(load);
}

const I18n = {
  use(messages) {
    I18n.messages = messages;
    return I18n.translate;
  },

  translate(strings, ...values) {
    const key = strings.slice(0, -1).reduceRight(
        (memo, curr, i) => `${curr}{${i}}${memo}`,
        strings[strings.length - 1]
    );

    return (I18n.messages[key] || key)
        .replace(/{(\d)}/g, (_, index) => values[Number(index)]);
  },
};