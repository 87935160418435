import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const pianoEntityUrl: any = {
    'Neste\\Services\\Piano\\Entity\\Licensee': 'https://dashboard-eu.piano.io/publisher/licensees/{id}/edit',
    'Neste\\Services\\Piano\\Entity\\User': 'https://dashboard-eu.piano.io/publisher/user/edit/all/overview?userId={id}',
    'Neste\\Services\\Piano\\Entity\\Contract': 'https://dashboard-eu.piano.io/publisher/licensees/contract/{id}/edit',
};

export default function PianoLink({ entity, ...props }: any) {

    return (
        <a
            href={pianoEntityUrl[entity.entity_type].replace('{id}', entity.entity_id)}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
            className={`block text-blue-700 underline ${props.className ? props.className : null}`}
        >
            {props.children ?? entity.entity_type.split('\\').pop()}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
        </a>
    )
}
