import React, { useState } from 'react';

export const FormContext: React.Context<any> = React.createContext({});

export default function Form(props: React.FormHTMLAttributes<HTMLFormElement>) {
    const [isDirty, setDirty] = useState(false);

    const handleInput = (event: React.FormEvent<HTMLFormElement>) => {
        setDirty(true);
        props.onInput && props.onInput(event);
    }

    return (
        <FormContext.Provider value={{ isDirty, setDirty }}>
            <form {...props} onInput={handleInput}>
                {props.children}
            </form>
        </FormContext.Provider>
    )
}
