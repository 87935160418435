import React, { useState } from 'react';

export default function DropFileZone({ children, onDrop, className, hoverClassName }: { children: any, onDrop: any, className?: any, hoverClassName?: any }) {
    const [showDropMask, setShowDropMask] = useState(false);

    const handleDragEnter = (e: React.DragEvent) => {
        setShowDropMask(true);

        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: React.DragEvent) => {
        setShowDropMask(false);

        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent) => {
        onDrop && onDrop(e);

        setShowDropMask(false);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className={`${className ?? 'border-2 border-dashed relative'} ${showDropMask && (hoverClassName ?? 'border-blue-500')}`}>
            {React.cloneElement(children, { onDragEnter: handleDragEnter })}
            {showDropMask && (
                <div
                    onDrop={handleDrop}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    className="absolute inset-0 opacity-50 bg-white">
                </div>
            )}
        </div>
    )
}
