import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface IDropDown {
    name?: string;
    optionsClassName?: string;
    className?: string | undefined
}

export interface IDropDownOptions {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DropDownContext: React.Context<{}> = React.createContext({});

export default function DropDown(props: any) {
    const children: any[] = React.Children.toArray(props.children);

    const [value, setValue] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<any | undefined>(Object.keys(children).find((key: any) => children[key].props.selected));
    const wrapperRef = useRef<HTMLSpanElement>(null);

    const handleToggleOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOpen(!open)
    }

    const inputId: string = Math.random().toString(20).substr(2, 6);

    const dropDown: IDropDownOptions = { value, setValue, isOpen: open, setOpen, ...props.context };

    useEffect(() => {
        setOpen(false)
    }, [selected]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (open && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, open]);

    return (
        <DropDownContext.Provider value={dropDown}>
            <span className={props.className} ref={wrapperRef}>
                <label htmlFor={`${inputId}_focus`} className="border rounded px-2 py-3 bg-white relative select-none whitespace-no-wrap cursor-pointer">
                    <input checked={open} className="hidden" type="checkbox" id={`${inputId}_focus`} onChange={handleToggleOpen} />
                    {children[selected] ? React.cloneElement(children[selected], { selected: true }) : null}
                    <FontAwesomeIcon icon={faChevronDown} className="mx-2 text-gray-500" />
                    {open &&
                        <div className={`z-10 bg-white border rounded absolute min-w-full ${props.optionsClassName ?? 'left-0 mt-2'}`}>
                            {children.map((option: any, i: number) => React.cloneElement(
                                option,
                                { key: i, selected: false, onClick: () => (option.props.onSelect ? option.props.onSelect() : setSelected(i)) }
                            ))}
                        </div>
                    }
                </label>
                {props.name && <input type="hidden" name={props.name} value={value} />}
            </span>
        </DropDownContext.Provider>
    )
}
