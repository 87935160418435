import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { _, translate } from '../Services/translate.js';
import { SearchOptionsContext, ISearchContext } from './useSearch';

interface IPagination {
    paginate: {
        pagination: IPaginationData | undefined
        setPaginationData: any
        currentPage: number | undefined
        goToPage: any
        canGoToPage: any
    }
}

interface IPaginationData {
    current_page: number;
    first_page_url: string;
    from: number | null;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
    total: number;
}

export default function Pagination({ pagination }: { pagination: IPaginationData | undefined }) {
    translate({
        '{0}-{1} of {2}': '{0}-{1} of {2}',
    });
    const { pagination: { goToPage, canGoToPage } } = useContext(SearchOptionsContext);

    if (!pagination) {
        return null
    }

    return (
        <div className="px-8 border-b border-gray-400 py-1 flex justify-end">
            <div className="flex">
                <span className="self-center mx-2">
                    {_`${pagination.from || 0}-${pagination.to || 0} of ${pagination.total}`}
                </span>
                <button
                    className={`text-2xl mx-2 ${!canGoToPage(1) ? 'text-gray-200' : ''}`}
                    disabled={!canGoToPage(1)}
                    onClick={() => goToPage(1)}>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} fixedWidth />
                </button>
                <button
                    className={`text-2xl mx-2 ${!canGoToPage(pagination.current_page - 1) ? 'text-gray-200' : ''}`}
                    disabled={!canGoToPage(pagination.current_page - 1)}
                    onClick={() => goToPage(pagination.current_page - 1)}>
                    <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
                </button>
                <button
                    className={`text-2xl mx-2 ${!canGoToPage(pagination.current_page + 1) ? 'text-gray-200' : ''}`}
                    disabled={!canGoToPage(pagination.current_page + 1)}
                    onClick={() => goToPage(pagination.current_page + 1)}>
                    <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                </button>
                <button
                    className={`text-2xl mx-2 ${!canGoToPage(pagination.last_page) ? 'text-gray-200' : ''}`}
                    disabled={!canGoToPage(pagination.last_page)}
                    onClick={() => goToPage(pagination.last_page)}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} fixedWidth />
                </button>
            </div>
        </div>
    )
}

export function usePaginate(context: any): [typeof pagination, typeof setPaginationData, typeof goToPage, typeof canGoToPage] {
    const [pagination, setPaginationData] = useState<IPaginationData | undefined>();
    const searchContext = useContext<ISearchContext>(context);

    function canGoToPage(page: number) {
        return page >= 1 && pagination && page !== pagination.current_page && page <= pagination.last_page;
    }

    function goToPage(page: number) {
        canGoToPage(page) && searchContext.dispatch({ type: 'set', param: 'page', value: page });
    }

    return [pagination, setPaginationData, goToPage, canGoToPage];
}