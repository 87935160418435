import React, { useState, useContext } from 'react';
import { __RouterContext } from 'react-router';
import { _, translate } from '../../Services/translate.js';
import Neo from '../../Services/neo.js';
import Button from '../../components/Button';
import Navigation from '../../components/Video/Navigation';

export default function Create() {
    translate({
        'Title': 'Tittel',
        'Create video': 'Opprett video',
        'New Video: {0}': 'Ny video: {0}',
    });

    const [errors, setErrors] = useState<any | undefined>();
    const routerContext = useContext(__RouterContext);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget);

        Neo.createVideo(formData)
            .then((response: any) => {
                routerContext.history.push(`/video/${response.data.id}`);
            })
            .catch((error: any) => {
                setErrors(error.response.data.errors);
            });
    }

    return (
        <div className="flex min-h-screen bg-white">
            <div className="flex-grow">
                <Navigation />

                <form onSubmit={handleSubmit} className="px-8 max-w-4xl">
                    <div className="mx-0 my-32 md:mx-32">
                        <div className="mb-8">
                            <label htmlFor="title" className="inline-block mb-1">{_`Title`}</label>
                            <input
                                id="title"
                                type="text"
                                name="title"
                                defaultValue={_`New Video: ${new Date().toLocaleString()}`}
                                className="block bg-gray-200 border rounded w-full py-1 px-2 leading-normal" />
                            {errors?.title && errors.title.map((message: string, i: number) =>
                                <div key={i} className="mt-1 text-red-500">{message}</div>
                            )}
                        </div>
                        <Button
                            type="submit"
                            className="block mx-auto bg-green-500 text-white hover:text-white hover:bg-green-700 py-4 px-8 text-l">
                            {_`Create video`}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

