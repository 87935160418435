import React, {useState, useEffect, useReducer} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import File from 'components/File';
import { _, translate } from 'Services/translate.js';
import { thumb } from 'Services/image';

export default function ImagesInput({useUploadImages, name, mainImageInputName, images: inputImages, errors}: {useUploadImages: any, name: any, mainImageInputName: any, images: any, errors: any[]}) {
    translate({
        'Select image': 'Velg bilde',
    });
    
    const [selectedImages, dispatchSelectedImages] = useUploadImages;
    const [images, setImages] = useState(inputImages);
    const [mainImage, setMainImage] = useState<{id: number| undefined, isNew: boolean}>(
        {id: images.find((image: any) => image.pivot.is_main)?.image_id, isNew: false}
    );
    const [fullImagePreview, setFullImagePreview] = useState<string | null>(null);

    const handleSelectImageFile = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            dispatchSelectedImages({type: 'add', files: [...e.currentTarget.files].map((file: any) => new File(file))});
            e.currentTarget.value = '';
            if ((images.length + selectedImages.length) === 0) {
                setMainImage({id: 0, isNew: true})
            }
        }
    }

    const handleRemoveSelectedImageFile = (e: any, index: number) => {
        dispatchSelectedImages({ type: 'remove', index});

        e.preventDefault();
        e.stopPropagation();
    }

    const handleRemoveImage = (e: any, imageId: number) => {
        setImages(images.filter((image: any) => image.image_id !== imageId));

        e.preventDefault();
        e.stopPropagation();
    }

    useEffect(() => {
        setImages(inputImages);
        dispatchSelectedImages({ type: 'reset' });
        setMainImage({id: inputImages.find((image: any) => image.pivot.is_main)?.image_id, isNew: false})
    }, [inputImages]);

    return (
        <>
            <input type="hidden" name={name} />
            <input type="hidden" name={mainImageInputName} defaultValue={mainImage.id} />
            <input type="hidden" name={`${mainImageInputName}_is_new`} defaultValue={mainImage.isNew ? 1 : 0} />

            {images.map((image: any, i: number) => (
                <div
                    key={i}
                    className={`w-32 h-20 mr-2 mb-2 relative group cursor-pointer bg-center bg-cover${!mainImage.isNew && image.image_id === mainImage.id ? ' border-4 border-blue-500' : ''}`}
                    onClick={() => setMainImage({id: image.image_id, isNew: false})}
                    style={{backgroundImage: `url('${thumb(image.original_source, 128, 80)}')`}}>
                        <button
                            type="button"
                            className="bg-white absolute bottom-0 right-0 group-hover:block hidden p-2"
                            onClick={(e: any) => handleRemoveImage(e, image.image_id)}>
                            <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                        </button>
                        <input type="hidden" name={name} value={image.image_id} />
                </div>
            ))}

            {selectedImages.map((image: any, i: number) => (
                <div
                    key={i}
                    className={`w-32 h-20 mr-2 mb-2 relative group cursor-pointer bg-center bg-cover${mainImage.isNew && i === mainImage.id ? ' border-4 border-blue-500' : ''}`}
                    onClick={() => setMainImage({id: i, isNew: true})}
                    style={{backgroundImage: `url(${image.blobUrl})`}}>
                        <button type="button"
                            className="bg-white absolute bottom-0 right-0 group-hover:block hidden p-2"
                            onClick={(e: any) => handleRemoveSelectedImageFile(e, i)}>
                            <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                        </button>
                </div>
            ))}

            <label
                htmlFor="image_file"
                className="flex items-center justify-center border-2 border-dashed w-32 h-20 mr-2 mb-2 cursor-pointer">
                <FontAwesomeIcon icon={faPlus} />&nbsp; {_`Select image`}
                <input onChange={handleSelectImageFile} multiple accept="image/*" type="file" id="image_file" className="hidden" />
            </label>

            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-1 text-red-500">{message}</div>
            )}

            {fullImagePreview && (
                <div className="fixed top-0 left-0 flex z-10 items-center justify-center w-screen h-screen">
                    <div
                        className="absolute inset-0 opacity-50 bg-white"
                        onClick={() => setFullImagePreview(null)} />
                    <button type="button" className="z-20 absolute rounded-full mt-2 mr-4 bg-white border-1 border-black border-solid px-4 text-4xl top-0 right-0" onClick={() =>  setFullImagePreview(null)}>&times;</button>
                    <img alt="" src={fullImagePreview} className="z-10"/>
                </div>
            )}
        </>
    );
}

export function useUploadImages() {
    function reducer(state: any, action: { type: string, files?: any, index?: number }) {
        switch (action.type) {
            case 'add':
                return [...state, ...action.files];
            case 'remove':
                return state.filter((e: any, key: number) => key !== action.index);
            case 'reset':
                return [];
        }
    }
    
    return useReducer(reducer, []);
}
