export default class File {
    public file: globalThis.File;
    private memoBlobUrl: string | null;

    constructor(file: any) {
        this.file = file;
        this.memoBlobUrl = null;
    }

    public get name() {
        return this.file.name;
    }

    public get size() {
        return this.file.size;
    }

    public get blobUrl() {
        return this.memoBlobUrl
            ? this.memoBlobUrl
            : this.memoBlobUrl = URL.createObjectURL(this.file);
    }

    public get readableSize() {
        const i = Math.floor(Math.log(this.file.size) / Math.log(1024)),
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        return (this.file.size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    }
}
