import React from 'react';
import * as Jsondiffpatch from 'jsondiffpatch';

interface IJsonDiff {
    right: any;
    left: any;
    annotated?: boolean;
}

const formatters = Jsondiffpatch.formatters;

export default function JsonDiff({ right, left }: IJsonDiff) {
    const delta = Jsondiffpatch.create({ }).diff(left, right);
    const html = delta && formatters.html.format(delta, left);
    if (!html) {
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
