import React, { PropsWithChildren, useState, useEffect } from 'react';
import {_, translate} from '../../Services/translate.js'
import Neo from '../../Services/neo.js';
import styles from './ExternalAuthorList.module.scss'

interface IAuthor {
    id: number;
    name: string;
    description: string;
}

interface IRowEdit {
    author?: IAuthor;
    updateEditngAuthor: (changed: {[key: string]: string | null}) => void;
    errors: {name?: string, description?: string};
}

export default function ExternalAuthorList({'article-id': articleId}: {'article-id': number }) {
    const [authors, setAuthors] = useState<IAuthor[]>([]);
    const [editingAuthor, setEditingAuthor] = useState<IAuthor | undefined>();
    const [newAuthor, setNewAuthor] = useState<{name: string, description: string } | undefined>();
    const [errors, setErrors] = useState<{[index: string]: any}>();

    translate({
        'External Authors': 'Eksterne Forfattere',
        'No external authors': 'Ingen eksterne forfattere',
        'Name': 'Navn',
        'Description': 'Beskrivelse',
        'Name: ': 'Navn: ',
        'Description: ': 'Beskrivelse: ',
        'Edit': 'Redigere',
        'Delete': 'Slette',
        'Save': 'Lagre',
        'Create': 'Legg til',
        'Cancel': 'Avbryt',
        'Are you sure?': 'Er du sikker?',
    });

    const getAuthors = () => Neo.getExternalAuthors(articleId)
        .then(response => setAuthors(response.data))

    const handleEdit = (authorId: number) =>
        setEditingAuthor(authors.find((author: IAuthor) => author.id === authorId))

    const clearErrors = () =>
        setErrors(undefined)

    const handleCancel = () => {
        setEditingAuthor(undefined);
        clearErrors();
    }

    const handleUpdate = (authorId: number) => editingAuthor && Neo.updateExternalAuthors(editingAuthor)
        .then((response) => {
            let index = authors.findIndex(o => o.id === response.data.id);
            authors[index] = response.data;

            setAuthors(authors);
            handleCancel();
        })
        .catch((error: any) => {
            setErrors({...errors, [editingAuthor.id]: error.response.data.errors});
        });

    const handleDelete = (id: number) =>
        window.confirm(_`Are you sure?`) && Neo.deleteExternalAuthors(id)
            .then(author => setAuthors(authors.filter((author: IAuthor) => author.id !== id)));

    const handleNew = () => setNewAuthor({
        name: '',
        description: '',
    });

    const handleCancelCreate = () => {
        setNewAuthor(undefined);
        clearErrors();
    }

    const handleCreate = () => Neo.createExternalAuthors({article_id: articleId, ...newAuthor})
        .then(response => {
            setAuthors([...authors, response.data])
            setNewAuthor(undefined);
        })
        .catch((error: any) => {
            setErrors({...errors, new: error.response.data.errors});
        });

    useEffect(() => {
        getAuthors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h3>{_`External Authors`}</h3>
            <table className="bg-white table-auto w-full lg:w-1/2 border-gray-800 border-t-0 border-r-0 border-b border-l-0 border-solid border-collapse">
                <thead>
                    <tr className="bg-gray-900 text-white font-bold">
                        <th className="p-2 w-1/3">{_`Name`}</th>
                        <th className="p-2">{_`Description`}</th>
                        <th className="p-2 w-1/4"></th>
                    </tr>
                </thead>

                <tbody>
                    {authors.length === 0 &&
                        <tr key="no-authors"><td className="text-center p-2" colSpan={3}>{_`No external authors`}</td></tr>
                    }
                    {authors.map(author =>
                         author.id === editingAuthor?.id
                            ? <RowEdit
                                key={author.id}
                                updateEditngAuthor={(changed: any) => setEditingAuthor({...editingAuthor, ...changed})}
                                author={author}
                                errors={errors && errors[author.id]}>
                                <Button
                                    onClick={handleCancel}
                                    className="bg-orange-500 text-white hover:text-white hover:bg-orange-700">
                                    {_`Cancel`}
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={() => handleUpdate(author.id)}
                                    className="bg-green-500 text-white hover:text-white hover:bg-green-700">
                                    {_`Save`}
                                </Button>
                            </RowEdit>
                            : <Row author={author} key={author.id}>
                                <Button
                                    onClick={() => handleEdit(author.id)}
                                    className="bg-orange-500 text-white hover:text-white hover:bg-orange-700">
                                    {_`Edit`}
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={() => handleDelete(author.id)}
                                    className="bg-red-500 text-white hover:text-white hover:bg-red-700">
                                    {_`Delete`}
                                </Button>
                            </Row>

                    )}
                    {typeof newAuthor === 'undefined'
                        ? <tr key="create-new">
                            <td className="text-center p-2" colSpan={3}>
                                <Button
                                    onClick={handleNew}
                                    className="bg-green-500 text-white hover:text-white hover:bg-green-700">
                                    {_`Create`}
                                </Button>
                            </td>
                        </tr>
                        : <RowEdit
                            key="creating-new"
                            updateEditngAuthor={(changed: any) => setNewAuthor({...newAuthor, ...changed})}
                            errors={errors?.new}>
                            <Button
                                onClick={handleCancelCreate}
                                className="bg-orange-500 text-white hover:text-white hover:bg-orange-700">
                                {_`Cancel`}
                            </Button>
                            &nbsp;
                            <Button
                                onClick={handleCreate}
                                className="bg-green-500 text-white hover:text-white hover:bg-green-700">
                                {_`Create`}
                            </Button>
                        </RowEdit>
                    }
                </tbody>
            </table>
        </div>
    );
}

function Button(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button {...props} className={`font-bold py-2 px-4 rounded border-0 cursor-pointer ${props.className}`}>
            {props.children}
        </button>
    )
}

function Row({author, children}: PropsWithChildren<{author: IAuthor}>) {
    return (
        <tr className={styles.row}>
            <td className="p-2">{author.name}</td>
            <td className="p-2">{author.description}</td>
            <td className={`${styles.actions} p-2 text-right align-middle`}>
                {children}
            </td>
        </tr>
    )
}

function RowEdit({author, updateEditngAuthor, children, errors}: PropsWithChildren<IRowEdit>) {
    return (
        <tr className={`${errors && `bg-red-200`} shadow border-gray-400 border-solid border-t-0 border-r-0 border-b border-l-0`}>
            <td className="h-1 p-2">
                <span className="select-none absolute bold">{_`Name: `}</span>
                <div
                    className="pt-10"
                    onInput={e => updateEditngAuthor({name: e.currentTarget.textContent})}
                    contentEditable="true"
                    suppressContentEditableWarning>
                    {author?.name}
                </div>
                {errors?.name && <div className="select-none text-red-700 italic text-center">{errors.name}</div>}
            </td>
            <td className="h-1 p-2">
                <span className="select-none absolute bold">{_`Description: `}</span>
                <div
                    className="pt-10"
                    onInput={e => updateEditngAuthor({description: e.currentTarget.textContent})}
                    contentEditable="true"
                    suppressContentEditableWarning>
                    {author?.description}
                </div>
                {errors?.description && <div className="select-none text-red-700 italic text-center">{errors.description}</div>}
            </td>
            <td className="p-2 text-right align-middle">
                {children}
            </td>
        </tr>
    )
}

