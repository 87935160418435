import React, {useState, useContext, useEffect} from 'react';
import {_, translate} from '../Services/translate.js';
import {SearchOptionsContext, ISearchContext} from './useSearch';

interface IFilter {
    name: string;
    value: string | number;
}

interface IReducerAction {
    type: string;
    filter?: string;
    index?: number;
}

export default function Filter({filters, defaultFilter}: any) {
    const {filter: {dispatch}} = useContext<{ filter: { dispatch: any } }>(SearchOptionsContext);
    const [selectedFilter, setSelectedFilter] = useState<number | undefined>();

    translate({
        'Filter': 'Søk etter navn, `invoice id: 12345` eller `group id: 1234`',
    });

    const fieldNames: { [key: string]: string } = {
        'title': 'Tittel',
        'name': 'Navn',
        'group_id': 'Group id',
        'invoice_id': 'Invoice id',
    }

    const removeFilter = (index: number) => {
        dispatch({type: 'remove', index})
    }

    const handleEnterOrBackspace = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '') {
            if (selectedFilter !== undefined) {
                dispatch({type: 'remove', index: selectedFilter});
                setSelectedFilter(undefined);
            } else {
                setSelectedFilter(filters.length - 1)
            }
        } else {
            setSelectedFilter(undefined);
        }


        if (e.key !== 'Enter' || e.currentTarget.value === '') {
            return;
        }

        let filter = {name: defaultFilter, value: e.currentTarget.value};

        for (const [key, value] of Object.entries(fieldNames)) {
            if (e.currentTarget.value.toLowerCase().startsWith(value.toLowerCase())) {
                filter.name = key;
                filter.value = e.currentTarget.value.toLowerCase().replace(`${value.toLowerCase()}:`, '').trim()
            }
        }

        dispatch({type: 'add', filter: filter})
        e.currentTarget.value = '';
    }

    return (
        <div className="px-8 border-b border-gray-400 py-1 flex flex-col md:flex-row">
            <div className="flex flex-wrap whitespace-no-wrap max-w-full md:max-w-3/4">
                {filters.map((filter: IFilter, i: number) => (
                    <span key={i}
                          className={`m-1 px-4 leading-loose bg-gray-400 rounded-full inline-block${selectedFilter === i ? ' outline-none shadow-outline' : ''}`}>
                        {`${fieldNames[filter.name]}: ${filter.value}`}
                        <button className="px-4 py-1 -mr-4 font-bold" onClick={() => removeFilter(i)}>&times;</button>
                    </span>
                ))}
            </div>
            <input
                className="my-2 leading-loose focus:outline-none flex-grow"
                type="text"
                onKeyDown={handleEnterOrBackspace}
                placeholder={_`Filter`}/>
        </div>
    );
}

export function useFilter(context: any) {
    const {search, dispatch: dispatchSearch} = useContext<ISearchContext>(context);
    const initialState: IFilter[] = loadFiltersFromSearch(search);
    const [state, setState] = useState(initialState);

    function reducer(state: IFilter[], action: IReducerAction) {
        switch (action.type) {
            case 'add':
                return [...state, action.filter];
            case 'remove':
                return state.filter((e: any, key: number) => key !== action.index);
            case 'init':
                return loadFiltersFromSearch(search);
            default:
                throw new Error();
        }
    }

    function loadFiltersFromSearch(searchParams: URLSearchParams) {
        let filters: IFilter[] = [];

        searchParams.forEach(function (value, key) {
            if (key.startsWith('filter[')) {
                filters.push({
                    name: key.substring(7, key.length - 3),
                    value
                });
            }
        });

        return filters;
    }

    function setFilter(action: IReducerAction) {
        const newSearchParams = new URLSearchParams();
        const newState = reducer(state, action);

        newState.forEach((filter: any) => newSearchParams.append(`filter[${filter.name}][]`, String(filter.value)));

        dispatchSearch({
            type: 'replace',
            search: newSearchParams.toString()
        });
    }

    useEffect(() => {
        setState(loadFiltersFromSearch(search));
    }, [search]);

    return [state, setFilter];
}
