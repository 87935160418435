import axios from "axios";

const clearStringNbsp = (item) =>
    typeof item === "string" ? item.replace(/&nbsp;/g, " ") : item;

const toFormData = (data) =>
    data instanceof FormData
        ? data
        : Object.keys(data).reduce((formData, key) => {
              formData.append(key, data[key]);
              return formData;
          }, new FormData());

export default {
    getVideo: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=Video${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),

    getVideoTags: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=VideoTag${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),

    getVideoActivities: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=VideoActivity${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),

    updateVideo: (id, attributes, options) =>
        axios({
            method: "post",
            url: `/service.php?section=Neste&service=Video&id=${id}`,
            data: (() => {
                const formData = toFormData(attributes);
                formData.append("_method", "PUT");
                return formData;
            })(),
            headers: {
                "Content-Type": "multipart/form-data",
            },
            ...options,
        }),

    deleteVideo: (id) =>
        axios({
            method: "delete",
            url: `/service.php?section=Neste&service=Video&id=${id}`,
        }),

    createVideo: (attributes) =>
        axios({
            method: "post",
            url: `/service.php?section=Neste&service=Video`,
            data: toFormData(attributes),
        }),

    getVideos: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=Video${
                searchParams ? `&${searchParams.toString()}` : ""
            }`,
        }),

    getProductGroups: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=ProductGroup${
                searchParams ? `&${searchParams.toString()}` : ""
            }`,
        }),

    getCustomer: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=Customer${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),

    getCustomers: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=Customer${
                searchParams ? `&${searchParams.toString()}` : ""
            }`,
        }),

    createCustomer: (formData) =>
        axios({
            method: "post",
            url: `/service.php?section=Neste&service=Customer`,
            data: formData,
        }),

    updateCustomer: (id, attributes) =>
        axios({
            method: "put",
            url: `/service.php?section=Neste&service=Customer&id=${id}`,
            data: Object.fromEntries(attributes),
        }),

    getSubscriptionGroups: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=SubscriptionGroup${
                searchParams ? `&${searchParams.toString()}` : ""
            }`,
        }),

    getSubscriptionGroup: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=SubscriptionGroup${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),

    createSubscriptionGroup: (formData) =>
        axios({
            method: "post",
            url: `/service.php?section=Neste&service=SubscriptionGroup`,
            data: formData,
        }),

    updateSubscriptionGroup: (id, attributes) =>
        axios({
            method: "put",
            url: `/service.php?section=Neste&service=SubscriptionGroup&id=${id}`,
            data: Object.fromEntries(attributes),
        }),

    createSubscriptionGroupPiano: (id) =>
        axios({
            method: "post",
            url: `/service.php?section=Neste&service=SubscriptionGroupPiano&id=${id}`,
        }),

    deleteSubscriptionGroup: (id) =>
        axios({
            method: "delete",
            url: `/service.php?section=Neste&service=SubscriptionGroup&id=${id}`,
        }),

    getSubscriptionGroupActivities: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=SubscriptionGroupActivity${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),
    getArticleConversions: (searchParams) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=ArticleConversion${
                searchParams ? `&${new URLSearchParams(searchParams)}` : ""
            }`,
        }),
    getExternalAuthors: (articleId) =>
        axios({
            method: "get",
            url: `/service.php?section=Articles&service=Author&article_id=${articleId}`,
        }),

    createExternalAuthors: ({ article_id, ...attributes }) =>
        axios({
            method: "post",
            url: `/service.php?section=Articles&service=Author&article_id=${article_id}`,
            data: attributes,
        }),

    updateExternalAuthors: ({ id, ...attributes }) =>
        axios({
            method: "put",
            url: `/service.php?section=Articles&service=Author&id=${id}`,
            data: attributes,
        }),

    deleteExternalAuthors: (id) =>
        axios({
            method: "delete",
            url: `/service.php?section=Articles&service=Author&id=${id}`,
        }),

    getInvoicesOfGroup: (groupId) =>
        axios({
            method: "get",
            url: `/service.php?section=Neste&service=SubscriptionGroupInvoice&groupId=${groupId}`,
        }),

    refundInvoiceById: (dataToSend) =>
        axios({
            method: "put",
            url: `/service.php?section=Neste&service=SubscriptionGroupInvoice`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: dataToSend,
        }),

    getContractUsersUrl: (groupId) =>
        `/service.php?section=Neste&service=PianoContractUsers&id=${groupId}`,
};
