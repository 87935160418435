import React from 'react';

interface ISitesInput {
    name: string;
    sites: any;
    errors: any[];
}

export default function SitesInput({ name, sites, errors }: ISitesInput) {
    const siteOptions: { id: number, name: string }[] = [
        { id: 63, name: 'Tu.no' },
        { id: 64, name: 'Digi.no' },
    ];

    return (
        <>
            <input type="hidden" name={name} />
            {siteOptions.map((site) => (
                <label key={site.id} htmlFor={`sites_${site.id}`} className="inline-block mb-1 mr-8">
                    <input
                        className="mr-2"
                        id={`sites_${site.id}`}
                        type="checkbox"
                        name={name}
                        defaultValue={site.id}
                        defaultChecked={!!(sites.find(({ nettstedid: siteId }: any) => siteId === site.id))}
                    />
                    {site.name}
                </label>
            ))}
            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-1 text-red-500">{message}</div>
            )}
        </>
    );
}
