import React, { useEffect, useRef } from 'react';

export default function useEffectNoInitialMount(effect: React.EffectCallback, deps?: React.DependencyList | undefined) {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effect()
        }
    }, deps);
}

