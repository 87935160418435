import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loading, { LoadingContext, useLoading } from '../Loading';
import UploadStatus, { UploadContext, useUpload } from '../UploadStatus';

export default function App(props: any) {
    const [isLoading, setLoading] = useLoading();
    const useUploadContext = useUpload();

    return (
        <HashRouter>
            <LoadingContext.Provider value={{ isLoading, setLoading }}>
                <UploadContext.Provider value={useUploadContext}>
                    <Loading />
                    <UploadStatus />
                    <Switch>
                        <Route path="/video/dashboard/:search?" component={require('../../pages/Video/Dashboard').default} />
                        <Route path="/video/create" component={require('../../pages/Video/Create').default} />
                        <Route path="/video/:id/activities" component={require('../../pages/Video/Activities').default} />
                        <Route path="/video/:id" component={require('../../pages/Video/Update').default} />

                        <Route path="/statistics/conversions" component={require('../../pages/Statistics/Conversions').default} />
                    </Switch>
                </UploadContext.Provider>
            </LoadingContext.Provider>
        </HashRouter>
    );
}

