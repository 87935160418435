import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { _, translate } from '../Services/translate.js';
import Button from './Button';
import Wrapper from './Wrapper';
import File from './File';

interface IUploadFile {
    file: File;
    signal: any;
}

export const UploadContext: React.Context<any> = React.createContext({ upload: undefined, uploads: [], setUpload: undefined, errors: {}, setErrors: undefined });

export default function UploadStatus() {
    return (
        <UploadContext.Consumer>
            {({ uploads, setUpload, errors, setErrors, progress, setProgress }: { uploads: IUploadFile[], setUpload: any, errors: any, setErrors: any, progress: any, setProgress: any }) => (
                uploads.length > 0 && (
                    <div className="fixed bottom-0 left-0 z-30">
                        {uploads.map((upload: any, i: number) => (
                            <div key={i} className={`m-5 bg-white shadow-md ${errors[upload.id] ? 'border border-red-700' : ''}`}>
                                <div className="flex px-4 py-6 items-start">
                                    <div className="flex-grow overflow-auto w-64">
                                        <Wrapper
                                            condition={typeof progress[upload.id] === 'undefined'}
                                            wrapper={(children: any) => <Link to={`/video/${upload.id}`}>{children}</Link>}
                                        >
                                            <Status
                                                size={upload.file.size}
                                                loaded={progress[upload.id]?.loaded}
                                                hasError={typeof errors[upload.id] !== 'undefined'}
                                            />
                                            <div className="truncate font-bold">{upload.file.name}</div>
                                            {errors[upload.id] && (
                                                <ul className="text-red-700">
                                                    {Object.keys(errors[upload.id]).map(function (field: string) {
                                                        return errors[upload.id][field].map((message: string, i: number) => (
                                                            <li key={i}>{message}</li>
                                                        ));
                                                    })}
                                                </ul>
                                            )}
                                        </Wrapper>
                                    </div>

                                    {progress[upload.id]
                                        ? <Button
                                            type="button"
                                            className="ml-4 bg-gray-200 text-black hover:text-black hover:bg-gray-400 rounded-lg"
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                upload.signal.cancel();
                                                setUpload(uploads.filter((uploadObject: any) => uploadObject.id !== upload.id));
                                                setErrors({ ...errors, [upload.id]: undefined });
                                            }}>
                                            {_`Cancel`}
                                        </Button>
                                        : <Button
                                            type="button"
                                            className="ml-4 bg-gray-200 text-black hover:text-black hover:bg-gray-400 rounded-lg"
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                setUpload(uploads.filter((uploadObject: any) => uploadObject.id !== upload.id));
                                                setErrors({ ...errors, [upload.id]: undefined });
                                            }}>
                                            {_`Close`}
                                        </Button>
                                    }
                                </div>
                                {progress[upload.id] && <Progress max={upload.file.size} value={progress[upload.id].loaded}></Progress>}
                            </div>
                        ))}
                    </div>
                )
            )}
        </UploadContext.Consumer>
    );
}

function Progress({ max = 100, value }: { max: number, value: number }) {
    return (
        <div className="relative pt-1">
            <div className="overflow-hidden h-4 text-xs flex bg-gray-200">
                <div style={{ width: `${value / max * 100}%` }} className="flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
            </div>
        </div>
    );
}

function Status({ size, loaded, hasError }: { size: number, loaded: number | undefined, hasError: boolean }) {
    translate({
        'Done': 'Done',
        'Processing': 'Processing',
        'Uploading': 'Uploading',
    });

    if (hasError) {
        return null;
    }
    if (!loaded) {
        return <div className="text-green-500 text-sm">{_`Done`}</div>
    }
    if (loaded >= size) {
        return <div className="text-gray-500 text-sm">{_`Processing`}</div>
    }

    return <div className="text-gray-500 text-sm">{_`Uploading`}</div>
}

export function useUpload() {
    const [uploads, setUpload] = useState<IUploadFile[]>([]);
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState({});

    function upload(request: any, attributes: any[], { id, file }: { id: number, file: any }) {
        const uploadFile = { id, file, signal: axios.CancelToken.source() };

        setUpload([
            ...uploads.filter((uploadObject: any) => uploadObject.id !== uploadFile.id),
            uploadFile
        ]);
        setErrors({ ...errors, [id]: undefined });
        setProgress({ ...progress, [id]: undefined });

        return request(...[...attributes, {
            cancelToken: uploadFile.signal.token,
            onUploadProgress: (progressEvent: any) => setProgress({ ...progress, [id]: progressEvent })
        }])
            .catch((errorMessages: any) => {
                !axios.isCancel(errorMessages) && setErrors({ ...errors, [id]: errorMessages.response.data.errors });
                throw errorMessages;
            })
            .finally(() => {
                setProgress({ ...progress, [id]: undefined });
            });
    }

    return { upload, uploads, setUpload, errors, setErrors, progress, setProgress };
}
