import React from 'react';

interface IClock {
    children: string;
    time: string;
    options?: Intl.DateTimeFormatOptions;
    locale?: string | string[] | undefined;
}

/* Documentation on options
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#Parameters
*/
export default function Clock({ children, time, options = {}, locale = 'nb-NO' }: IClock) {
    const dateTimeFormat = new Intl.DateTimeFormat(locale, {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...options
    })

    const clock = dateTimeFormat.formatToParts(new Date(time.replace(/-/g, "/"))).reduce((acc, cur) => {
        return acc.replace(`@${cur.type}`, cur.value);
    }, children);

    return <>{clock}</>;
}


export class DateTime extends Date {
    constructor(date?: string) {
        date ? super(date) : super();
        Object.setPrototypeOf(this, DateTime.prototype);
    }

    toMysqlDate() {
        const fixedTimezone = new Date(this.getTime());
        fixedTimezone.setHours(this.getHours() - this.getTimezoneOffset() / 60);

        return fixedTimezone.toJSON()?.substr(0, 10);
    }

    toMysqlTime() {
        const fixedTimezone = new Date(this.getTime());
        fixedTimezone.setHours(this.getHours() - this.getTimezoneOffset() / 60);

        return fixedTimezone.toJSON()?.substr(11, 8);
    }
    
    toMysqlDateTime() {
        return [this.toMysqlDate(), this.toMysqlTime()].join(' ');
    }
}
