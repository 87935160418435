import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { _, translate } from '../../Services/translate.js';

interface IVideoOption {
    selected?: boolean;
    dropDown?: any;
    onSelect?: any;
    className?: string | undefined;
}

const Select = function({ selected, dropDown, ...props }: IVideoOption) {
    translate({ 'Select': 'Velg' });

    return <span {...props} className={!selected ? 'hidden' : props.className}>
        {_`Select`}
    </span>
}

const Delete = function({ selected, dropDown, ...props }: IVideoOption) {
    translate({ 'Delete': 'Fjern' });

    return <span {...props}>
        <FontAwesomeIcon icon={faTimes} className="mx-2 text-red-500" fixedWidth />
        {_`Delete`}
    </span>
}

export default {
    Select,
    Delete
}
