import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

// interface ILoadinContext {
//     isLoading: boolean;
//     setLoading: React.Dispatch<React.SetStateAction<boolean>>;
// }

export const LoadingContext: React.Context<any> = React.createContext({ isLoading: undefined, setLoading: undefined });

export default function Loading() {
    return (
        <LoadingContext.Consumer>
            {({ isLoading }) => (
                isLoading && <div className="fixed inset-0 flex items-center justify-center text-3xl opacity-50 bg-white z-50">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )}
        </LoadingContext.Consumer>
    )
}

export function useLoading() {
    return useState(false);
}