import React, { useState, useContext, useEffect, useRef } from 'react';
import { __RouterContext } from 'react-router';

export interface ISearchContext {
    search: URLSearchParams;
    dispatch: any;
}

interface IReducerAction {
    type: string;
    param?: string | number;
    value?: string | number;
    search?: URLSearchParams;
}

export function useSearch({ urlBase }: { urlBase: string }) {
    const routerContext = useContext(__RouterContext);
    const [search, setSearch] = useState<URLSearchParams>(new URLSearchParams(routerContext.location.search));
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setSearch(new URLSearchParams(routerContext.location.search));
        }
    }, [routerContext]);

    function reducer(state: any, action: IReducerAction) {
        switch (action.type) {
            case 'set':
                action.value !== ''
                    ? state.set(action.param, String(action.value))
                    : state.delete(action.param);
                break;
            case 'replace':
                state = action.search;
                break;
            default:
                throw new Error();
        }

        routerContext.history.push(urlBase + '?' + state.toString());
    }

    const SearchContext = React.createContext({
        search,
        dispatch: (action: IReducerAction) => reducer(search, action),
    } as ISearchContext);

    return [search, SearchContext];
}

export const SearchOptionsContext = React.createContext<any>({});
