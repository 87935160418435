import React, { useEffect, useState, useReducer, useContext } from 'react';
import Neo from '../../Services/neo.js';
import { DropDownContext } from '../DropDown';

const Autocomplete = function ({ selected, ...props }: any) {

    const {
        useSelectedTags: {selectedTags, dispatchSelectedTags, tagValue, setTagValue},
        useTagOptions: [, setTagOptions],
        isOpen,
        setOpen
    } = useContext<any>(DropDownContext);

    const handleEnter = (e: any) => {
        e.key === 'Enter' && dispatchSelectedTags({ type: 'add', name: tagValue })
    }

    useEffect(() => {
        async function fetchTags() {
            const { data: {data} } = await Neo.getVideoTags({ 'filter[name][]': tagValue });
            setTagOptions(data.filter((tag: any) => (!selectedTags.includes(tag.name))));
        }

        if (tagValue !== '' || isOpen) {
            fetchTags();
            setOpen(true);
        };
    }, [tagValue]);

    return <span {...props} className={!selected ? 'hidden' : props.className}>
        <input
            onKeyDown={handleEnter}
            onChange={(e) => setTagValue(e.currentTarget.value)}
            value={tagValue}
            className=""
            type="text" />
    </span>
}


const Option = function ({ tag, ...props }: any) {
    return (
        <span {...props}>{tag.name}</span>
    );
}

export function useSelectedTags() {
    const [tagValue, setTagValue] = useState('');

    function reducer(state: any, action: { type: string, name?: string, index?: number, tags?: [] }) {
        switch (action.type) {
            case 'add':
                setTagValue('');
                return action.name !== '' ? [...state, action.name] : state;
            case 'remove':
                return state.filter((e: any, key: number) => key !== action.index);
            case 'init':
                return action.tags;
        }
    }

    const [selectedTags, dispatchSelectedTags] = useReducer(reducer, []);

    return {selectedTags, dispatchSelectedTags, tagValue, setTagValue};
}


export function SelectedTagsInput({name, useSelectedTags, errors}: {name: any, useSelectedTags: any, errors: any[]}) {
    const {selectedTags, dispatchSelectedTags} = useSelectedTags;

    return (
        <>
            <div className="py-2">
                <input type="hidden" name={name} />
                {selectedTags.map((tag: string, i: number) => (
                    <span key={i} className="m-1 px-4 bg-gray-200 hover:bg-gray-400 rounded-full inline-block">
                        <input type="hidden" name={name} value={tag} />
                        {tag}
                        <button type="button" className="px-4 py-1 -mr-4" onClick={() => dispatchSelectedTags({ type: 'remove', index: i })}>&times;</button>
                    </span>
                ))}
            </div>
            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-1 text-red-500">{message}</div>
            )}
        </>
    );
}

export default {
    Autocomplete,
    Option
}
