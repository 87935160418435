import React from 'react';
import { _, translate } from '../../Services/translate.js';

export default function AccessInput({name, requireSubscription, errors}: {name: any, requireSubscription: any, errors: any[]}) {
    translate({
        'Extra': 'Ekstra',
    });

    return (
        <>
            <label htmlFor="require_subscription" className="inline-block mb-1 cursor-pointer">
                <input type="hidden" name={name} defaultValue={0} />
                <input
                    className="mr-2"
                    id="require_subscription"
                    type="checkbox"
                    name={name}
                    defaultValue={1}
                    defaultChecked={requireSubscription}
                />
                {_`Extra`}
            </label>
            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-1 text-red-500">{message}</div>
            )}
        </>
    );
}
