import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

document.querySelectorAll("component").forEach(component => {

    const {src, ...props} = [...component.attributes].reduce((result: any, attr: any) => {
        result[attr.nodeName] = attr.value;
        return result;
    }, {});

    props.children = component.innerHTML;

    const NeoComponent = require('./components/' + src).default;

    ReactDOM.render(
        <React.StrictMode>
         <NeoComponent {...props}/>
        </React.StrictMode>,
        component
    )
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
