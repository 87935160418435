import React, { useState, useEffect, useContext } from 'react';
import { useParams, __RouterContext } from 'react-router';
import axios from 'axios';
import Neo from '../../Services/neo.js';
import { _, translate } from '../../Services/translate.js';
import DropDown from '../../components/DropDown';
import Button from '../../components/Button';
import Clock from '../../components/Clock';
import { LoadingContext } from '../../components/Loading';
import { UploadContext } from '../../components/UploadStatus';
import AccessInput from '../../components/Video/AccessInput';
import SitesInput from '../../components/Video/SitesInput';
import PublishedOptions from '../../components/Video/PublishedOptions';
import ImagesInput, { useUploadImages } from '../../components/Video/ImagesInput';
import SubtitlesFileInput from '../../components/Video/SubtitlesFileInput';
import VideoInput, { useUploadVideo } from '../../components/Video/VideoInput';
import TagOptions, { SelectedTagsInput, useSelectedTags } from '../../components/Video/TagOptions';
import Navigation from '../../components/Video/Navigation';
import DropFileZone from '../../components/DropFileZone';
import File from '../../components/File';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

interface IVideo {
    id: number;
    title: string;
    description: string;
    require_subscription: boolean;
    published_at: string | null;
    preview_url: string;
    path: string | null;
    articles: IArticle[];
    images: IImage[];
    sites: ISite[];
    subtitles: ISubtitle[];
}

interface IArticle {
    artikkelid: number;
    overskrift: string;
    relativUrl: string;
    site: ISite;
}

interface IImage {
    original_source: string;
}

interface ISubtitle {
    locale: string;
    content: string;
}

interface ISite {
    nettstedid: number;
    url: string;
    navn: string;
}

interface ITag {
    name: string;
}

export default function Update() {
    translate({
        'Upload video': 'Video',
        'Add': 'Legg til',
        'Title': 'Tittel',
        'Description': 'Ingress',
        'Subtitles': 'Undertekst',
        'Tags': 'Emne',
        'Site': 'Nettsted',
        'Access': 'Tilgang',
        'Status: ': 'Status: ',
        'Articles': 'Tilknyttede artikler',
        'No video links in articles': 'Ingen videolinker i artikler',
        'Published': 'Publisert',
        'Publish': 'Publisering',
        'Actions:': 'Handling:',
        'Save changes': 'Lagre endringer',
        'Delete video': 'Slett video',
        'Unpublished': 'Upublisert',
        'Draft': 'Kladd',
        'Sites': 'Nettsteder',
        'Are you sure?': 'Er du sikker?',
    });

    const routerContext = useContext(__RouterContext);
    const { setLoading } = useContext(LoadingContext);
    const { upload } = useContext(UploadContext);
    const [video, setVideo] = useState<IVideo>();
    const [selectedVideo, setSelectedVideo] = useUploadVideo();
    const [errors, setErrors] = useState<any | undefined>();
    const [tagOptions, setTagOptions] = useState<any>([]);
    const {selectedTags, dispatchSelectedTags, tagValue, setTagValue} = useSelectedTags();
    const { id } = useParams();

    const [selectedImages, dispatchSelectedImages] = useUploadImages();
    const [deleteSubtitle, setDeleteSubtitle] = useState<boolean>(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true);

        const formData = new FormData(event.currentTarget);

        selectedImages.map((image: any) => {
            formData.append('image_files[]', image.file);
        });

        if (deleteSubtitle) {
            formData.append('subtitle_deleted', '1');
        }

        video && Neo.updateVideo(video.id, formData)
            .then((response: any) => {
                setVideo(response.data);
            })
            .catch((error: any) => {
                setErrors(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleDelete = () => {
        if (window.confirm(_`Are you sure?`) === false) {
            return;
        }

        setLoading(true);

        video && Neo.deleteVideo(video.id)
            .then(() => {
                routerContext.history.push(`/video/dashboard`);
            })
            .catch((error: any) => {
                setErrors(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            })
    }


    const handleDropImage = (e: React.DragEvent) => {
        dispatchSelectedImages({ type: 'add', files: [...e.dataTransfer.files].map((file: any) => new File(file)) });
    }

    useEffect(() => {
        async function fetchVideo() {
            const response = await Neo.getVideo({ id });
            setVideo(response.data);
            dispatchSelectedTags({ type: 'init', tags: response.data.tags.map(({ name }: ITag) => name) });
            setLoading(false);
        }

        setLoading(true);
        fetchVideo();
    }, [id]);

    useEffect(() => {
        setErrors(undefined);
        if (selectedVideo) {
            const data = {
                video_file: selectedVideo.file,
            };

            video && upload(Neo.updateVideo, [video.id, data], { id: video.id, file: selectedVideo })
                .then((response: any) => {
                    setVideo({
                        ...video,
                        images: response.data.images,
                        preview_url: response.data.preview_url,
                        path: response.data.path
                    });
                    setSelectedVideo(undefined);
                })
                .catch((error: any) => {
                    !axios.isCancel(error) && setErrors(error.response.data.errors);
                });
        }
    }, [selectedVideo]);

    return (
        <div className="flex flex-grow bg-white">
            <div className="flex-grow">
                <Navigation />

                {video && <form onSubmit={handleSubmit} className="px-8 max-w-4xl">
                    {/* Disable Submit on Enter key */}
                    <button type="submit" disabled className="hidden" aria-hidden="true" />

                    <div className="my-8">
                        <h2 className="my-8 text-xl font-bold">{_`Upload video`}</h2>
                        <VideoInput
                            name="path"
                            video={video}
                            useUploadVideo={[selectedVideo, setSelectedVideo]}
                            errors={errors?.video_file} />
                    </div>

                    <div className="my-8">
                        <DropFileZone onDrop={handleDropImage} className="relative" hoverClassName="border-2 border-dashed border-blue-500">
                            <div className="flex flex-wrap">
                                <ImagesInput
                                    useUploadImages={[selectedImages, dispatchSelectedImages]}
                                    name="images[]"
                                    mainImageInputName="main_image"
                                    images={video.images}
                                    errors={errors?.title} />
                            </div>
                        </DropFileZone>
                    </div>
                    <div className="my-8 border-t border-black">
                        <h2 className="my-8 text-xl font-bold">{_`Subtitles`}</h2>
                        <div className="mb-8">
                            <SubtitlesFileInput
                                name="subtitle_file"
                                subtitleContentName="subtitle_content"
                                setDeleteSubtitle={setDeleteSubtitle}
                                subtitle={video.subtitles?.[0]}
                                errors={errors?.subtitle_file} />
                        </div>
                    </div>
                    <div className="my-8 border-t border-black">
                        <h2 className="my-8 text-xl font-bold">{_`Egenskaper`}</h2>
                        <div className="mb-8">
                            <label htmlFor="title" className="inline-block mb-1">{_`Title`}</label>
                            <input
                                id="title"
                                type="text"
                                name="title"
                                defaultValue={video.title}
                                className="block bg-gray-200 border rounded w-full py-1 px-2 leading-normal"
                            />
                            {errors?.title && errors.title.map((message: string, i: number) =>
                                <div key={i} className="mt-1 text-red-500">{message}</div>
                            )}
                        </div>
                        <div className="mb-8">
                            <label htmlFor="description" className="inline-block mb-1">{_`Description`}</label>
                            <textarea
                                id="description"
                                name="description"
                                className="block bg-gray-200 border rounded w-full py-1 px-2 leading-normal"
                                defaultValue={video.description}
                            />
                            {errors?.description && errors.description.map((message: string, i: number) =>
                                <div key={i} className="mt-1 text-red-500">{message}</div>
                            )}
                        </div>
                        <div className="mb-8">
                            <div className="mb-1">{_`Tags`}</div>
                            <DropDown className="inline-block my-2" context={{
                                useSelectedTags: {selectedTags, dispatchSelectedTags, tagValue, setTagValue},
                                useTagOptions: [tagOptions, setTagOptions],
                            }}>
                                <TagOptions.Autocomplete selected className="inline-block" />
                                {tagOptions.map((tag: any, i: number) => (
                                    <TagOptions.Option
                                        key={i}
                                        tag={tag}
                                        onSelect={() => dispatchSelectedTags({ type: 'add', name: tag.name })}
                                        className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4" />
                                ))}
                            </DropDown>
                            <Button
                                type="button"
                                className="ml-8 bg-blue-500 text-white hover:text-white hover:bg-blue-700"
                                onClick={() => dispatchSelectedTags({ type: 'add', name: tagValue })}
                                children={_`Add`}
                            />
                            <SelectedTagsInput name="tags[]" useSelectedTags={{selectedTags, dispatchSelectedTags}} errors={errors?.tags} />
                        </div>
                        <div className="mb-8">
                            <div className="mb-1">{_`Access`}</div>
                            <AccessInput name="require_subscription" requireSubscription={video.require_subscription} errors={errors?.require_subscription} />
                        </div>
                        <div className="mb-8">
                            <div className="mb-1">{_`Sites`}</div>
                            <SitesInput name="sites[]" sites={video.sites} errors={errors?.sites} />
                        </div>
                    </div>

                    <div className="my-8 border-t border-black">
                        <h2 className="my-8 text-xl font-bold">{_`Articles`}</h2>
                        <ul>
                            {video.articles.length === 0 && (
                                <li className="py-2">
                                    {_`No video links in articles`}
                                </li>
                            )}
                            {video.articles.map((article: IArticle, key) => (
                                <li key={key} className="py-2 italic">
                                    {article.site.navn}: <a className="hover:underline text-blue-500" href={`/?section=Editor&articleId=${article.artikkelid}`} target="_blank">
                                        {article.overskrift}
                                        <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2"/>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="my-8 border-t border-black">
                        <h2 className="my-8 text-xl font-bold">{_`Publish`}</h2>
                        <div className="bg-gray-200 py-8 px-4">
                            <div className="mb-5">
                                <span className="font-bold">{_`Status: `}</span>
                                {video.published_at
                                    ? <>{_`Published`} <Clock time={video.published_at}>@hour:@minute - @day. @month @year</Clock></>
                                    : _`Draft`
                                }
                            </div>
                            <DropDown className="mr-8" name="published_at" optionsClassName='left-0 bottom-0 mb-10'>
                                <PublishedOptions.Now
                                    className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4"
                                    selectedClassName="inline-block" />
                                <PublishedOptions.Future
                                    selected={!!video.published_at}
                                    value={video.published_at}
                                    className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4"
                                    selectedClassName="inline-block" />
                                <PublishedOptions.Unpublished
                                    selected={!video.published_at}
                                    className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4"
                                    selectedClassName="inline-block" />
                            </DropDown>
                            <Button
                                type="submit"
                                className="mr-8 bg-blue-500 text-white hover:text-white hover:bg-blue-700">
                                {_`Save changes`}
                            </Button>

                            <Button
                                type="button"
                                onClick={handleDelete}
                                className="mr-8 bg-red-500 text-white hover:text-white hover:bg-red-700">
                                {_`Delete video`}
                            </Button>
                            {errors?.published_at && errors.published_at.map((message: string, i: number) =>
                                <div key={i} className="mt-1 text-red-500">{message}</div>
                            )}
                        </div>
                    </div>
                </form>}
            </div>
        </div>
    );
}
