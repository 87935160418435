import React, { useState, useEffect } from 'react';
import { _, translate } from '../../Services/translate.js';
import File from '../File';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import VideoOptions from './VideoOptions';
import DropDown from '../DropDown';
import DropFileZone from '../DropFileZone';

export default function VideoInput({ name, video, useUploadVideo, errors }: { name: any, video: any, useUploadVideo: any, errors: any[] }) {
    translate({
        'or': 'eller',
        'Drop here to upload': 'Slipp her for å laste opp',
        'Select file': 'Velg fil',
    });

    const [selectedVideo, setSelectedVideo] = useUploadVideo;
    const [videoPath, setVideoPath] = useState<string | undefined>(video.path);

    const handleSelectFile = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            setSelectedVideo(new File(e.currentTarget.files[0]));
        }
    }

    const handleDrop = (e: React.DragEvent) => {
        setSelectedVideo(new File(e.dataTransfer.files[0]));
    }

    const handleDeleteVideoFile = () => {
        setSelectedVideo(undefined);
        setVideoPath(undefined);
    }

    useEffect(() => {
        setVideoPath(video.path)
    }, [video]);

    return (
        <>
            {selectedVideo && (
                <div>
                    <video className="w-full" controls>
                        <source src={selectedVideo.blobUrl} type="video/mp4" />
                    </video>
                    <div className="mt-4 flex justify-between">
                        <div>
                            <div className="">{selectedVideo.name}</div>
                            <div className="text-gray-500">{selectedVideo.readableSize}</div>
                        </div>
                        <div className="">
                            <DropDown optionsClassName="right-0 mt-2">
                                <VideoOptions.Select selected className="inline-block" />
                                <VideoOptions.Delete
                                    onSelect={handleDeleteVideoFile}
                                    className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4" />
                            </DropDown>
                        </div>
                    </div>
                </div>
            )}
            {!selectedVideo && videoPath && (
                <div>
                    <video className="w-full" controls style={{ maxHeight: '50vh' }}>
                        <source src={video.preview_url} type="video/mp4" />
                    </video>
                    <div className="mt-4 flex justify-between">
                        <div>
                            <div className="">{video.path}</div>
                        </div>
                        <div className="">
                            <DropDown optionsClassName="right-0 mt-2">
                                <VideoOptions.Select selected className="inline-block" />
                                <VideoOptions.Delete
                                    onSelect={handleDeleteVideoFile}
                                    className="hover:bg-gray-200 block p-2 whitespace-no-wrap pr-4" />
                            </DropDown>
                        </div>
                    </div>
                </div>
            )}
            {!selectedVideo && !videoPath && (
                <DropFileZone onDrop={handleDrop}>
                    <div className="w-full h-64 flex flex-col items-center justify-center">
                        <input type="hidden" name={name} />
                        <div className="font-bold text-lg m-2">
                            {_`Drop here to upload`}
                        </div>
                        <div className="m-2">
                            {_`or`}
                        </div>
                        <label
                            htmlFor="video_file"
                            className="py-2 px-4 rounded border-0 cursor-pointer m-2 bg-blue-500 text-white hover:text-white hover:bg-blue-700">
                            <FontAwesomeIcon icon={faPlus} /> {_`Select file`}
                            <input onChange={handleSelectFile} type="file" id="video_file" className="hidden" />
                        </label>
                    </div>
                </DropFileZone>
            )}

            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-2 text-red-500">{message}</div>
            )}
        </>
    );
}

export function useUploadVideo() {
    return useState<File | undefined>();
}
