import React, { useState, useEffect, useContext } from 'react';
import { useParams, __RouterContext } from 'react-router';
import { _, translate } from '../../Services/translate.js';
import Neo from '../../Services/neo.js';
import Navigation from '../../components/Video/Navigation';
import { LoadingContext } from '../../components/Loading';
import Clock from 'components/Clock';
import JsonDiff from 'components/JsonDiff';

interface ICauser {
    fornavn: string;
    etternavn: string;
    epost: string;
}

interface IActivity {
    id: number;
    description: string;
    properties: string;
    created_at: string;
    causer: ICauser;
}

export default function Activities() {
    translate({
        'Title': 'Tittel',
        'Create video': 'Opprett video',
        'New Video: {0}': 'Ny video: {0}',
    });

    const { id } = useParams();
    const [activities, setActivities] = useState<IActivity[]>([]);
    const [diff, setDiff] = useState<{current: IActivity, prev: IActivity}>();
    const [preview, setPreview] = useState<number>();
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        async function fetchVideoActivities() {
            const response = await Neo.getVideoActivities({ id });
            setActivities(response.data.data);
            setLoading(false);
        }

        setLoading(true);
        fetchVideoActivities();
    }, [id]);

    useEffect(() => {
        activities.some(function (currentValue, index, arr) {
            if (currentValue.id === preview) {
                setDiff({current: currentValue, prev: arr[index+1] ?? ''})
                return true;
            }

            return false;
        });
    }, [preview]);

    return (
        <div className="flex min-h-screen bg-white">
            <div className="flex-grow">
                <Navigation />
                <div className="flex flex-row h-full">
                    <table className={`video-dashboard-table table-auto border-collapse ${diff ? 'w-1/2' : 'w-full'}`}>
                        <thead>
                            <tr className="border-0 border-b border-gray-400 hidden">
                                <th className="text-left p-2 pl-8">
                                    {_``}
                                </th>
                                <th className="text-left w-40 p-2">
                                    {_``}
                                </th>
                                <th className="text-left w-60 p-2">{_``}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activities.map((activity, i) => <ActivityRow key={i} activity={activity} preview={preview} setPreview={setPreview} />)}
                        </tbody>
                    </table>
                    {diff && (
                        <div className="p-2 bg-gray-200 w-1/2">
                            <JsonDiff right={diff.current.properties} left={diff.prev.properties} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function ActivityRow({ activity, preview, setPreview }: { activity: IActivity, preview?: number, setPreview: any }) {
    return (
        <tr
            className={`border-0 border-b border-gray-400 ${preview === activity.id ? 'bg-gray-200' : 'hover:bg-gray-200'} cursor-pointer`}
            onClick={() => setPreview(activity.id)}
        >
            <td className="p-2 pl-8">
                { activity.causer && (
                    <span>{activity.causer?.fornavn} {activity.causer?.etternavn} ({activity.causer?.epost})</span>
                )}
                { !activity.causer ? 'User unknown' : '' }
            </td>
            <td className="p-2">
                {activity.description}
            </td>
            <td className="p-2">
                <Clock time={activity.created_at}>@hour:@minute - @day. @month @year</Clock>
            </td>
        </tr>
    );
}


