import React, { useState, useEffect, useContext } from 'react';
import Neo from 'Services/neo.js';
import { IArticleConversion } from 'interfaces/Article';
import ArticleConversionTable from 'components/Conversions/ArticleConversionTable';

export default function Conversions() {
    const now = new Date();
    const [articles, setArticles] = useState<IArticleConversion[]>([]);
    const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(now.getDate()-30)));
    const [toDate, setToDate] = useState<Date>(now);
    const [sites, setSites] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchArticleConversions =  async () => {
        setLoading(true);
        const {data} = await Neo.getArticleConversions({fromDate: formatDate(fromDate), toDate: formatDate(toDate)});
        setArticles(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchArticleConversions();
    }, [toDate, fromDate, sites])

    const formatDate = (date: Date) => date.toISOString().split('T')[0];
    const updateSites = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSites(parseInt(event.target.value));
    }
    const updateFromDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromDate(new Date(event.target.value));
    }
    const updateToDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToDate(new Date(event.target.value));
    }

    return (
        <div className="p-4">
            <div className="flex flex-row flex-wrap">
                <div className="mb-8 pr-4 w-1/3">
                    <label htmlFor="sites" className="inline-block mb-1">Nettsted:</label>
                    <div className="inline-block py-2 px-4 rounded ml-2 border border-solid border-gray-400">
                        <select id="sites" name="sites" onChange={updateSites}>
                            <option value="0">Alle</option>
                            <option value="63">Tu.no</option>
                            <option value="64">Digi.no</option>
                            <option value="102">InsideTelecom</option>
                        </select>
                    </div>
                </div>

                <div className="mb-8 pr-4 w-1/3">
                    <label htmlFor="from_date" className="inline-block mb-1">Fra:</label>
                    <div className="inline-block py-2 px-4 rounded ml-2 border border-solid border-gray-400">
                        <input id="from_date" type="date" name="from_date" onChange={updateFromDate} defaultValue={formatDate(fromDate)} />
                    </div>
                </div>
                <div className="mb-8 pr-4 w-1/3">
                    <label htmlFor="to_date" className="inline-block mb-1">Til:</label>
                    <div className="inline-block py-2 px-4 rounded ml-2 border border-solid border-gray-400">
                        <input id="to_date" type="date" name="to_date" onChange={updateToDate} defaultValue={formatDate(toDate)} />
                    </div>
                </div>
            </div>
            {!loading && <ArticleConversionTable articles={articles} />}
        </div>
    );
}

