import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import File from '../File';
import { _, translate } from '../../Services/translate.js';
import Button from '../Button';

export default function SubtitlesFileInput({name, errors, subtitle, setDeleteSubtitle, subtitleContentName}: {name: any, setDeleteSubtitle: any, subtitleContentName: any, errors: any[], subtitle: any}) {
    translate({
        'Select subtitles': 'Velg underteksfil',
        'Upload': 'Last opp',
        'Save changes': 'Lagre endringer',
        'Remove': 'Fjern',
    });

    const [selectedSubtitle, setSelectedSubtitle] = useState<File | undefined>();
    const fileInput = useRef<HTMLInputElement>(null);

    const handleSelectFile = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            setSelectedSubtitle(new File(e.currentTarget.files[0]));
        }
    }
    
    const handleCancelNewSubtitles = () => {
        setDeleteSubtitle(false);
        setSelectedSubtitle(undefined);
        if (fileInput && fileInput.current) {
            fileInput.current.value = '';
        }
    }
    
    useEffect(() => {
        handleCancelNewSubtitles();
    }, [subtitle]);

    return (
        <>
            {(subtitle && !selectedSubtitle) ? (
                <div className="mt-8 relative">
                    <textarea
                        defaultValue={subtitle.content}
                        name={subtitleContentName}
                        className="block bg-gray-200 border h-56 rounded w-full py-1 px-2 leading-normal focus:min-h-screen-80"
                    />

                    <div className="mt-5">
                        <Button
                            type="submit"
                            className="mr-8 bg-blue-500 text-white hover:text-white hover:bg-blue-700">
                            {_`Save changes`}
                        </Button>

                        <Button
                            type="submit"
                            onClick={() => setDeleteSubtitle(true)}
                            className="mr-8 bg-red-500 text-white hover:text-white hover:bg-red-700">
                            {_`Remove`}
                        </Button>
                    </div>
                </div>
            ) : (
                <label
                    htmlFor="subtitle_file"
                    className={`${selectedSubtitle ? 'hidden ' : ''}py-2 px-4 rounded border-0 cursor-pointer bg-blue-500 text-white hover:text-white hover:bg-blue-700`}>
                    <FontAwesomeIcon icon={faPlus} />&nbsp; {_`Select subtitles`}
                    <input onChange={handleSelectFile} ref={fileInput} type="file" id="subtitle_file" name={name} className="hidden" />
                </label>
            )}

            {selectedSubtitle && (
                <>
                    <span className="mr-8">{selectedSubtitle.name}</span>

                    <Button
                        type="submit"
                        className="mr-8 bg-blue-500 text-white hover:text-white hover:bg-blue-700">
                        {_`Upload`}
                    </Button>

                    <Button
                        type="button"
                        onClick={handleCancelNewSubtitles}
                        className="mr-8 bg-red-500 text-white hover:text-white hover:bg-red-700">
                        {_`Remove`}
                    </Button>
                </>
            )}

            {errors && errors.map((message: string, i: number) =>
                <div key={i} className="mt-1 text-red-500">{message}</div>
            )}
        </>
    );
}
