import React, { useRef, useEffect, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { DateTime } from '../Clock';
import { _, translate } from '../../Services/translate.js';
import { DropDownContext } from '../DropDown';

interface IPublishedOption {
    selected?: boolean;
    value?: any;
    dropDown?: any;
    className?: string | undefined;
    selectedClassName?: string | undefined;
}

const Now = function({ selectedClassName, selected, ...props }: IPublishedOption) {
    const { setValue } = useContext<any>(DropDownContext);

    translate({ 'Publish': 'Publiser' });

    useEffect(() => {
        selected && setValue(new DateTime().toMysqlDateTime());
    }, [selected, setValue]);

    return <span {...props} className={selected ? selectedClassName : props.className}>
        <FontAwesomeIcon icon={faCheckCircle} className="mx-2 text-green-500" fixedWidth />
        {_`Publish`}
    </span>
};

const Future = function({ selectedClassName, value, selected, ...props }: IPublishedOption) {
    translate({ 'Auto publish': 'Autopubliser' });
    const { setValue } = useContext<any>(DropDownContext);

    const dateRef = useRef<HTMLInputElement>(null);
    const timeRef = useRef<HTMLInputElement>(null);

    const defaultValue = new DateTime(value);

    const handleChange = useCallback(() => {
        const date = dateRef.current;
        const time = timeRef.current;
        if (date && time && date.value !== '' && time.value !== '') {
            setValue(new DateTime(`${date.value}T${time.value}`).toMysqlDateTime());
        } else {
            setValue('0');
        }
    }, [dateRef, timeRef, setValue]);

    useEffect(() => {
        selected && handleChange();
    }, [selected, handleChange]);

    return <span {...props} className={selected ? selectedClassName : props.className}>
        <FontAwesomeIcon icon={faClock} className="mx-2 text-orange-500" fixedWidth />
        {selected
            ? <span>
                <input ref={dateRef} type="date" defaultValue={defaultValue.toMysqlDate()} onChange={handleChange} />
                <input ref={timeRef} type="time" defaultValue={defaultValue.toMysqlTime()?.slice(0, -3)} onChange={handleChange} />
            </span>
            : _`Auto publish`
        }
    </span>
};

const Unpublished = function({ selectedClassName, selected, ...props }: IPublishedOption) {
    translate({
        'Unpublished': 'Avpubliser',
        'Draft': 'Kladd'
    });

    const { setValue } = useContext<any>(DropDownContext);

    useEffect(() => {
        selected && setValue('');
    }, [selected, setValue]);

    return <span {...props} className={selected ? selectedClassName : props.className}>
        {selected
            ? <FontAwesomeIcon icon={faCheckCircle} className="mx-2 text-gray-400" fixedWidth />
            : <FontAwesomeIcon icon={faTimes} className="mx-2 text-red-700" fixedWidth />
        }
        {selected ? _`Draft` : _`Unpublished`}
    </span>
};

export default {
    Now,
    Future,
    Unpublished
}
