import React from 'react';
import { IArticleConversion } from 'interfaces/Article';

export default function ArticleConversionTable ({articles}: {articles: IArticleConversion[]}) {
    return (
        <table className="table-auto text-sm">
        <thead className="text-left bg-gray-500">
            <tr>
                <th className="p-2">Overskrift</th>
                <th className="p-2">Nettsted</th>
                <th className="p-2">Artikkeltype</th>
                <th className="p-2">Forfattere</th>
                <th className="p-2">Emner</th>
                <th className="p-2">Konv.</th>
            </tr>
        </thead>
        <tbody>
            {articles.map((article, i) => <ArticleConversionRow key={i} article={article} even={!!(i%2)}/>)}
        </tbody>
        </table>
    )
}

function ArticleConversionRow ({article, even}: {article: IArticleConversion, even: boolean }) {
    const bg  = even ? 'bg-gray-200' : 'bg-gray-400';
    return (
        <tr className={`table-row leading-9 align-top text-left ${bg}`}>
            <td className="underline p-2"><a href={`${article.site.url}${article.relativUrl}`} target="_blank">{article.overskrift}</a></td>
            <td className="p-2">{article.site.navn}</td>
            <td className="p-2">{article.type.navn}</td>
            <td className="flex flex-col p-2">{article.authors.map((author, i) => <span key={i} className="whitespace-no-wrap">{author.fornavn} {author.etternavn}</span>)}</td>
            <td className="max-w-2xl p-2 overflow-hidden">{article.tags.map((tag, i) => <span  key={i} className="whitespace-no-wrap capitalize text-sm rounded-full p-2 mx-1 bg-green-200">{tag.emne} </span>)}</td>
            <td className="p-2">{article.conversion_count}</td>
        </tr>
    );
}
