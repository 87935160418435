import React, { useState, useEffect, useContext } from 'react';
import { _, translate } from 'Services/translate.js';
import Neo from 'Services/neo.js';
import Pagination, { usePaginate } from 'components/Pagination';
import Sort, { useSort } from 'components/Sort';
import Filter, { useFilter } from 'components/Filter';
import Navigation from 'components/Video/Navigation';
import Clock from 'components/Clock';
import { useSearch, SearchOptionsContext } from 'components/useSearch';
import { thumb } from 'Services/image';
import { __RouterContext } from 'react-router';

interface IVideo {
    id: number;
    title: string;
    description: string;
    published_at: string;
    images: IImage[];
    length: number;
}

interface IImage {
    original_source: string;
}

export default function Dashboard() {
    translate({
        'Title': 'Tittel',
        'Published': 'Publisert',
        'Views': 'Visning',
        'Filter': 'Søk etter nøkkelord',
        'Unpublished': 'Upublisert',
    });

    const [videos, setVideos] = useState<IVideo[]>([]);
    const [search, SearchContext] = useSearch({urlBase: '/video/dashboard/'});
    const [pagination, setPaginationData, goToPage, canGoToPage] = usePaginate(SearchContext);
    const [sort, toggleSort] = useSort(SearchContext);
    const [filters, dispatchFilter] = useFilter(SearchContext);

    useEffect(() => {
        async function fetchVideos() {
            const { data: {data, ...paginationData} } = await Neo.getVideos(search);
            setVideos(data);
            setPaginationData(paginationData);
        }

        fetchVideos();
    }, [search, setPaginationData]);

    return (
        <div className="flex min-h-screen bg-white">
            <div className="hidden w-64 bg-white min-h-screen overflow-y-hidden hover:overflow-y-auto border-r border-gray-400">
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
                Sidebar <br />
            </div>
            <div className="flex-grow">
                <Navigation />

                <SearchOptionsContext.Provider value={{
                    pagination: { goToPage, canGoToPage },
                    sort: { toggleSort },
                    filter: { dispatch: dispatchFilter },
                }}>
                    <Filter filters={filters} defaultFilter="title"  />
                    <table className="video-dashboard-table table-auto border-collapse w-full">
                        <thead>
                            <tr className="border-0 border-b border-gray-400">
                                <th className="text-left p-2 pl-8">
                                    <Sort sort={sort} field="title">{_`Title`}</Sort>
                                </th>
                                <th className="text-left w-40 p-2">
                                    <Sort sort={sort} field="published_at">{_`Published`}</Sort>
                                </th>
                                <th className="text-left w-32 p-2">{_`Views`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {videos.map((video, i) => <VideoRow key={i} video={video} />)}
                        </tbody>
                    </table>
                    <Pagination pagination={pagination} />
                </SearchOptionsContext.Provider>
            </div>
        </div>
    );
}

function VideoRow({ video }: { video: IVideo }) {
    const image = video.images.find((image: any) => image.pivot.is_main);
    const routerContext = useContext(__RouterContext);

    return (
        <tr className="border-0 border-b border-gray-400 hover:bg-gray-200 cursor-pointer" onClick={() => routerContext.history.push(`/video/${video.id}`)}>
            <td className="flex p-2 pl-8">
                <div
                    className="w-32 h-20 mr-2 mb-2 relative bg-center bg-cover bg-black"
                    style={{backgroundImage: image ? `url(${thumb(image.original_source, 128, 80)})` : undefined}}>
                        <span className="bg-black absolute bottom-0 right-0 m-1 px-1 text-xs text-white">
                            {new Date(video.length * 1000).toISOString().substr(11, 8).replace(/^00:/, '')}
                        </span>
                </div>
                <div>{video.title}</div>
            </td>
            <td className="p-2">
                {video.published_at
                    ? <Clock time={video.published_at}>@hour:@minute - @day. @month @year</Clock>
                    : _`Unpublished`
                }</td>
            <td className="p-2">@todo</td>
        </tr>
    );
}
