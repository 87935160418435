import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { _, translate } from '../../Services/translate.js';
import { __RouterContext } from 'react-router';

export default function Navigation() {
    translate({
        'Videos': 'Videoliste',
        'New video': 'Ny video',
    });

    const routerContext = useContext<any>(__RouterContext);

    const isSelected = (link: string) => routerContext.location.pathname.startsWith(link);

    const pageParams: {id?: number} = routerContext.match.params;

    return (
        <nav className="mt-8 px-8 border-b border-gray-400">
            <ul className="mb-4">
                <li className="inline mr-12">
                    <Link to="/video/dashboard" className={isSelected('/video/dashboard') ? 'border-b-2 border-blue-700 text-blue-700 bold pb-4 no-underline' : ''}>
                        {_`Videos`}
                    </Link>
                </li>
                <li className="inline mr-12">
                    <Link to="/video/create" className={isSelected('/video/create') ? 'border-b-2 border-blue-700 text-blue-700 bold pb-4 no-underline' : ''}>
                        {_`New video`}
                    </Link>
                </li>

                {routerContext.match.path.startsWith('/video/:id/activities') && (
                    <li className="inline mr-12">
                        <Link to={`/video/${pageParams.id}`}>
                            {_`Video`}
                        </Link>
                    </li>
                )}

                {routerContext.match.path.startsWith('/video/:id') && (
                    <li className="inline mr-12">
                        <Link to={`/video/${pageParams.id}/activities`} className={isSelected(`/video/${pageParams.id}/activities`) ? 'border-b-2 border-blue-700 text-blue-700 bold pb-4 no-underline' : ''}>
                            {_`Activities`}
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    );
}
